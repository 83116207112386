@font-face {
    font-family: 'Lato-Regular';
    src: url(./fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Black';
    src: url(./fonts/Lato-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url(./fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Thin';
    src: url(./fonts/Lato-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Light';
    src: url(./fonts/Lato-Light.ttf) format('truetype');
}

body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column;
    font-family: 'Lato-Regular';
}

#root {
    flex: 1;
    display: flex;
}